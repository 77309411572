
<div class="container">
   
  <div style="background-color:#ffffff; color:#000000; font-family:Consolas,'Courier New',monospace; font-size:16px; font-weight:normal; line-height:22px; white-space:pre">
    <h1 style="text-align:center"><span style="font-size:18px"><span style="color:#a31515">Thanks</span></span></h1>
    Message here.....</div>

    
  <div *ngIf="!loaded">
    <ngx-skeleton-loader *ngFor="let item of [1,2,3,4,5,6,7,8,9]"
      [theme]="{ 'border-radius': '5px', height: '10px',width:'100%'}"></ngx-skeleton-loader>
  </div>
  <p [innerHTML]="getContent()" *ngIf="loaded"></p>
</div>
